import  { useState} from "react";
import styles from "../../../util/styles";
import db from "../../../firebase";
import { doc, setDoc, } from "firebase/firestore";

const ContactForm = () => {
  const [namefirst, setNamefirst] = useState("");
  const [namelast, setNamelast] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [service, setService] = useState("");
  const [business, setBusiness] = useState("");

const [nameerror, namesetError] = useState(false);
const [nameerrorl, namesetErrorl] = useState(false);
const [emailerror, emailsetError] = useState(false);
const [messagerror, messagesetError] = useState(false);

const [loader, setLoader] = useState(false);
const [focus, setFocus] = useState(false);
const [submited, setSubmited] = useState(false);

const handleclick1 = ()=>{
    namesetError(false);
}
const handleclick2 = ()=>{
  emailsetError(false);
}
const handleclick3 = ()=>{
  messagesetError(false);
}
const handleclick4 = ()=>{
  namesetErrorl(false);
}
//  add business, website, company 
const validator = async (e: { preventDefault: () => void }) => {
  e.preventDefault();
  setSubmited(false);
  emailsetError(false);
  namesetError(false);
  messagesetError(false);
  if (namefirst.length < 3 ) {
    namesetError(true);
  } else if (namelast.length < 3 ) {
    namesetErrorl(true);
  }   
  
  else if(email.includes("@") === false) {
    emailsetError(true);
  }  else if(message.length < 10) {
    messagesetError(true);
  }  else {
    e.preventDefault();

   const dat2 = new Date().toISOString();
    setLoader(true);
    const data = {
      firstname: namefirst,
      lastname: namelast,
      email: email,
      message: message,
      service:service,
      business:business
    };
    setSubmited(true);
    const docRef = doc(db, "Email", `${dat2}`);
    await setDoc(docRef, data);
    setSubmited(true);
    setNamefirst("");
    setEmail("");
    setMessage("");
    setNamelast("");
    setService("");
    setBusiness("");
}
};

  return (
    <div
      className={`text-center ${styles.bblue1} w-4/5 h-2/3 rounded-lg lg:w-2/5 mt-6`}
    >
      <form
        className="flex flex-col rounded-lg justify-center z-20 backdrop-opacity-0"
        onSubmit={validator}
      >
        <div className="white__gradient -z-10 w-3/4 h-3/4 xs:w-2/3 md:top-5 md:left-5  top-20 right-64 absolute"></div>
        <div className="box-shadow -z-10 w-3/4 h-3/4 xs:w-2/3 md:top-5 md:left-5  absolute"></div>

        <h3 className={`text-center ${styles.bblue2} ${styles.heading2} `}>
          Lets Chat
        </h3>
        <span className={`${styles.bblue1}`}>-----</span>

              <div className="login-boxx">
            {/* Namefirst*/}
            <div className="flex flex-col justify-center items-center mt-3">
            <label
              className={`${styles.paragraph} ${styles.bblue1} font-bold mb-0`}
            >
             First Name
            </label>
            {nameerror && <p className="error"> Please Enter A Valid First Name  </p>}
            <input
              className={`w-[70%] m-4 mt-0 justify-center formtext  box-blur ${styles.bblue2}`}
              placeholder="Firstname*"
              value={namefirst}
              onClick={handleclick1}
              onChange={(e) => setNamefirst(e.target.value)}
            />
          </div>
           {/* Namelast*/}
           <div className="flex flex-col justify-center items-center mt-3">
            <label
              className={`${styles.paragraph} ${styles.bblue1} font-bold mb-0`}
            >
             Last Name
            </label>
            {nameerrorl && <p className="error"> Please Enter A Valid  Last Name  </p>}
            <input
              className={`w-[70%] m-4 mt-0 justify-center formtext  box-blur ${styles.bblue2}`}
              placeholder="lastname*"
              value={namelast}
              onClick={handleclick4}
              onChange={(e) => setNamelast(e.target.value)}
            />
          </div>

          {/* business */}
          <div className="flex flex-col justify-center items-center mt-3">
            <label
              className={`${styles.paragraph} ${styles.bblue1} font-bold mb-0`}
            >
              Business
            </label>
            <input
              className={`w-[70%] m-4 mt-0 justify-center formtext  box-blur ${styles.bblue2}`}
              placeholder="Business"
              value={business}
              onChange={(e) => setBusiness(e.target.value)}
            />
          </div>
          {/* email */}
          <div className="flex flex-col justify-center items-center mt-3">
            <label
              className={`${styles.paragraph} ${styles.bblue1} font-bold mb-0`}
            >
              Email
            </label>
            {emailerror && <p className="error"> Please Enter A Valid Email  </p>}
            <input
              className={`w-[70%] m-4 mt-0 justify-center 
              formtext 
              ${focus ? 'error' : ''}
             box-blur ${styles.bblue2} outline-none`}
              placeholder="Email*"
              value={email}
              onClick={handleclick2}
              onChange={(e) => setEmail(e.target.value) }
            />
          </div>

          {/* Service */}
          <div className="flex flex-col justify-center items-center mt-3">
            <label
              className={`${styles.paragraph} ${styles.bblue1} font-bold mb-0`}
            >
              Service 
            </label>
            <select 
            
              className={`w-[50%] m-4 mt-0 justify-center text-center 
              formtext 
              dropdown
              box-blur ${styles.bblue2} 
              bg-black
               outline-none  `}
              placeholder="Service"
              value={service}
              onChange={(e) => setService(e.target.value) }
            >
              <option value="SEO">SEO</option>
              <option value="Social Media">Social Media</option>
              <option value="Web Development" >Web Development</option>
              <option value="Email Marketing" >Email Marketing</option>
              <option value="PPC" > PPC</option>
              <option value="Other" >Other</option>
              </select>

          </div>
          {/* Message */}
          <div className="flex flex-col justify-center items-center mt-3">
            <label
              className={`${styles.paragraph} ${styles.bblue1} font-bold mb-0`}
            >
              How Can We Help
            </label>
            {messagerror && <p className="error"> Message is too short </p>}
            <textarea
              className={`w-[70%] m-4 mt-0 justify-center pb-0 resize-none overflow-y-auto
            formtext 
            textarea
            box-blur ${styles.bblue2}`}
              placeholder="Message*"
              value={message}
              onClick={handleclick3}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          {/* Submit */}
          <div className="flex flex-col justify-center items-center">
            <button
              className="w-[50%] m-4 mt-3 justify-center rounded-md formbutton text-black mb-12"
              type="submit"
              style={{ background: loader ? "#ffffff" : `#5ce1e6` }}
            >
              Submit
            </button>
            {submited && (<p className={`${styles.bblue1} ${styles.paragraph}`}> {`Thank You! We Will Get Back to you soon`}</p> )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
