import { motion } from "framer-motion";
import { useState } from "react";
import { Link } from "react-router-dom";

import { close, logo, logo2, menu } from "../../util/assets";
import { navLinks, socialMedia } from "../../util/constants";
import styles from "../../util/styles";

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () =>{
     if(window.scrollY >= 80){
       setColorchange(true);
     }
     else{
       setColorchange(false);
     }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const itemVariants = {
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        ease: "easeOut",
        duration: 0.6,
        delay: 1.0,
      },
    },
    open: {
      opacity: 1,
      transition: {
        ease: "easeOut",
        duration: 0.6,
      },
    },
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, y: "-100%" },
  };
  return (
    <nav className={`w-full flex py-4 justify-between items-center navbar fixed z-40 xs:pl-0 overflow-hidden xl:w-11/12 xxl:w-10/12 '}` }>
      <img src={logo2}  alt="Zardux Digital Logo"
      width={'100%'} height = {'inherit'} 
          title="Zardux Digital Logo" className="w-[4rem] h-[2.5rem]  md:w-[5rem] md:h-[3.5rem] bg-gray-gradient rounded-[10px] p-[.3rem] m-0 " />

      <ul className="list-none sm:flex hidden justify-end items-center flex-1 mr-20  xl:mr-1 xxl:mr-2">
        {navLinks.map((nav, index) => (
          <li
            key={nav.id}
            className={`font-poppins font-normal cursor-pointer navsmall text-[20px]
            ${colorChange ? `${styles.nav}` : ''}
            ${active === nav.title ? "text-white" : "text-dimWhite"} 
            ${index === navLinks.length - 1 ? "mr-0" : "mr-4"}`}
            onClick={() => setActive(nav.title)}
          >
            <Link to={nav.path}>{nav.title}</Link>
          </li>
        ))}
      </ul>

      <div className="sm:hidden flex flex-1 justify-end items-center align-middle fixed right-0  top-0 z-20">
        <img
        width={'100%'} height = {'inherit'} 
          src={toggle ? close : menu}
          alt="menu"
          title="menu"
          className="w-[28px] h-[28px] object-contain z-10 relative m-4 "
          onClick={() => setToggle(!toggle)}
        />
        {/* when at small screen */}

        <motion.div
          animate={toggle ? "open" : "closed"}
          // transition={{duration:1}}
          variants={itemVariants}
          className={`${
            !toggle ? "hidden" : "flex"
          } bg-black-gradient absolute top-0 right-0 h-screen w-screen z-[2] `}
        >
          <div className="list-none flex justify-center items-center w-[500] flex-1 mb-12 pt-8 flex-col ">
            {navLinks.map((nav, index) => (
              <motion.div
                animate={toggle ? "open" : "closed"}
                transition={{ duration: 0.7 }}
                variants={variants}
                key={nav.id}
                className={`font-poppins font-medium cursor-pointer navsmall text-[16px] p-6 
                ${styles.bblue1}
                ${active === nav.title ? "text-white" : "text-dimWhite"} 
                ${index === navLinks.length - 1 ? "mb-0" : "mb-4"}`}
                onClick={() => setActive(nav.title)}
              >
                <Link to={nav.path}  className={`font-poppins font-extrabold cursor-pointer navsmall  text-[32px] p-6 mt-20 pl-12
                ${styles.bblue3}`}>{nav.title}</Link>
              </motion.div>
            ))}
            <div className=" pt-32 -z-10 pl-6">
            <img src={logo} alt="Zardux Logo" title="Zardux Digital Logo" className=" relative w-[8rem] h-[3.5rem]  rounded-[10px]  -z-40" 
            width={'100%'} height = {'inherit'} 
            />
            
            </div>
          </div>

          <div className=" flex justify-center items-center flex-col z-[2]"> 
          <div className=' rounded flex py-4 flex-col '>
        {socialMedia.map((award) => (
      <div key={award.id} className ='m-4  w-7'>
        <img src = {award.icon}alt={award.name} title={award.title} 
        width={'100%'} height = {'inherit'} 
        onClick={() => window.open(award.link)}
       />
  
      </div>
            ))}
            </div>
           </div>
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;
