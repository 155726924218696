import CallToAction from "../components/CallToAction/CallToAction";
import Navbar from "../components/Navbar/Navbar";

import Stats from "../components/Stats/Stats";
import Carsoul from "../components/UI/Carsoul/Carsoul";
import styles from "../util/styles";
import Social from "../components/Stats/Social";
import Awards from "../components/Stats/Awards";
import Footer from "../components/Navbar/Footer";
import { test3, test7 } from "../util/assets";

import { useEffect } from "react";

import { Helmet } from "react-helmet-async";

import { logEvent,} from "firebase/analytics";
import { analytics } from "../firebase";


type Props = {};

const About = (props: Props) => {

  useEffect(() => {
   logEvent(analytics,'page_view_about');
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Zardux Digital</title>
        <meta
          name="description"
          content="Zardux Digtal is a full service digital marketing agency utilizing artifical intelligence to grow any buisness with SEO, Social Meida Marketing and Email Makerketing"
        />
        <meta
          name="keywords"
          content="Zardux Digital, Digital Marketing, SEO, Social Media Marketing, Email Marketing"
        />
        <meta name="author" content="Zardux Digital" />
        <meta property="og:url" content="https://zarduxdigital.com/about"/>
  <meta property="og:type" content="Website"/>
  <meta property="og:title" content="Zardux Digtal"/>
  <meta property="og:description"          content="Zardux Digtal is a full service digital marketing agency utilizing artifical intelligence to grow any buisness with SEO, Social Meida Marketing and Email Makerketing"/>
  <meta property="og:image" content="#"/>


  <meta name="twitter:card" content="summary_large_image"/>
  <meta property="twitter:domain" content="@zarduxdigital"/>
  <meta property="twitter:url" content="https://twitter.com/zarduxdigital"/>
  <meta name="twitter:title" content="Zardux Digtal"/>
  <meta name="twitter:description" content=""/>
  <meta name="twitter:image" content="#"/>
  <link rel="canonical" href="https://zarduxdigital.com/about" />
      </Helmet>

      <div className="bg-primary w-full  overflow-hidden ">
        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth} flex`}>
            <Navbar />
          </div>
        </div>
        <div className="flex flex-col justify-center text-center items-center ">
          <h1
            className={`${styles.bblue1} ${styles.heading1} text-center mt-28 box-blue`}
          >
            {" "}
            About Zardux Digital
          </h1>
          {/* <h1 className={`${styles.bblue3} w-full text-center ${styles.heading1} mt-2 underline`}> Our Moto </h1> */}
          <div
            className={`md:w-2/5 w-4/5 rounded-md bg-gray-gradient ${styles.bblue1} z-[1] p-3 `}
          >
            <h2
              className={` p-3 w-full text-center ${styles.paragraph} leading-9 `}
            >
              {" "}
              Our founder began the creators journey with the knowledge that his
              mission in this world was to help people build their dreams
              through business growth. That mission is now embedded in the heart
              of the Zardux family as we take entities to new heights through
              community focused marketing that strives on making sure the true
              essence of a brand is instilled in the minds of the perfect
              customer.
              <br />
              <br />
              Zardux digital is a small but immensely powerful team that is
              founded on a thirst for knowledge and an unquenchable desire for
              success. We are a team of creatives, thinkers, and are ready to
              take on any challenge to take your business to the next level.
              <br />
            </h2>
          </div>
          <div className="absolute  z-[0] w-[70%] xs:w-[100%] xs:h-[80%] h-[25%] -left-70 top-0  pink__gradient" />
          <div className="absolute -z-[1] md:w-[80%]  w-[70%] xs:w-[100%] xs:h-[80%] h-[80%] rounded-full white__gradient bottom-40" />
          <div className="absolute -z-[1] md:w-[50%] w-fill h-[50%] right-20 bottom-20 blue__gradient" />
        </div>
        <div className=" flex flex-col h-full  md:flex-row items-center text-center mt-28 xs:mt-8 px-10 justify-center mb-3">
          <div className=" flex justify-center  pr-8 pl-7 z-10 md:p-0  xs:mb-6 ">
            <img src={test7} alt=" About Zardux Digital" title="About Zardux Digital" 
                     width={'100%'} height = {'inherit'}
                      className="md:pl-9" />
            <div className=" xs:hidden w-2/3">
              <div className="absolute xs:w-[80%] xs:h-[100%]  md:w-[50%] md:h-[100%] md:opacity-10  left-10 bottom-4/5 white__gradient2  opacity-25" />
            </div>
          </div>
          <div className=" md:hidden w-2/3">
            <div className="absolute xs:w-[70%] xs:h-[100%]  md:w-[80%] md:h-[100%] md:opacity-10 bottom-4/5 white__gradient2  opacity-25" />
          </div>
          <div className="md:w-4/5">
            <h2
              className={`${styles.bblue6} w-full text-center ${styles.heading1}`}
            >
              {" "}
              Why Trust Us
            </h2>
            <h3
              className={` w-full text-center ${styles.paragraph} text-white xs:p-5 xs:pt-0 md:mx-10 md:pr-14 leading-9 `}
            >
              Any good agency can deliver on the basic requirments you bring
              them. But, a strategic agency works with you to uncover the true
              reason behind your challenge, the one that is blocking your
              business's growth.
              <br />
              At Zardux Digital, you are not just a customer but a partner and
              you’ll benefit from our phased approach. This method shows us your
              whole organization, allowing us to identify the best strategy, the
              tactics that will bring you success, and how you can improve upon
              that success.?{" "}
            </h3>
          </div>
        </div>
        <div className=" flex flex-col h-full md:flex-row p-3 items-center text-center mt-12 ml-0 pl-0 justify-around w-full m-3 md:p-16">
          <div className="xs:p-3 md:w-2/3">
            <h2 className={`${styles.bblue1}  text-center ${styles.heading1} `}>
              {" "}
              Service Mix{" "}
            </h2>
            <h3
              className={` w-full text-center ${styles.paragraph} text-white md:px-16 xs:px-5`}
            >
              {" "}
              Digital Makerting is a broad term that covers a wide range of
              services. We have a team of experts who are well versed and rather 
              than just offering a single service with every option, we offer a mix of services
              that are tailored to your business needs. To provide exactly what you need and 
              allow you to grow your business with cost consideration.{" "}
            </h3>
          </div>
          <div className="pr-8 pl-7 z-10 md:p-0 md:w-[900px] md:h-[600px]">
            <img
              src={test3}
              width={'100%'} height = {'inherit'}
              alt="Digtal Marketing Services"
              title="Digtal Marketing Services"
              className={"h-3/4 w-full"}
            />
          </div>
        </div>
        <div className=" flex justify-center h-full mt-32 mx-3 ">
          <Stats />
        </div>
        <div className="w-full justify-start items-center text-center mt-12 mb-0 z-2 xs:p-4">
          <div className="absolute z-[0] w-[40%] h-[35%]  xs:w-[90%] xs:h-[80%] top-70 pink__gradient opacity-60" />
          <div className="absolute z-[0] w-[80%] h-[80%] xs:w-[90%] xs:h-[80%] rounded-full white__gradient bottom-240 opacity-30" />
          <div className="absolute z-[0] w-[50%] h-[50%]  xs:w-[90%] xs:h-[80%]  xs:opacity-95 right-60 bottom-120 blue__gradient opacity-30" />
          <div className="sx:mx-1">
            <Carsoul />
          </div>
        </div>
        <div className=" flex h-full items-center text-center mt-12 justify-around sm:flex-col w-full">
          <div className=" flex justify-center sm:flex-col sm:flex xs:m-3 w-2/7">
            <Social />
          </div>
        </div>
        <div className=" flex h-full items-center text-center mt-12 justify-around sm:flex-col w-full">
          <div className=" flex justify-center sm:flex-col sm:flex xs:m-3 w-3/4">
            <Awards />
          </div>
        </div>
        <div className="absolute z-[0] w-[80%] h-[20%] xs:w-[100%] xs:h-[50%] xs:m-3 xs:-bottom-4/5 rounded-full bg-bluemain-gradient md:white__gradient right-3 -bottom-500 opacity-50 md:opacity-30 overflow-hidden" />
        <div className="mx-4 xs:m-3">
          <CallToAction />
        </div>
        <div className="p-8 xs:m-3">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default About;
