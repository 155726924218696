//pages
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import ServiceSingle from "./pages/ServiceSingle";
import { NotFound } from "./components/NotFound";
//router
import { createBrowserRouter,  RouterProvider } from "react-router-dom";

function App() {
  const router = createBrowserRouter([
    {
      path:'*',
      element: <NotFound />,
    },
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "about",
      element: <About />,
    },
    {
      path: "contact",
      element: <Contact />,
    },
    {
      path: "services",
      element: <Services />,
      // children: [
      //   {
      //     path: "services/:id",
      //     element: <Test />,
      //   },
      // ],
    },
    {
       path: "services/:id",
      element: <ServiceSingle />,
    },
  ]);





  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
