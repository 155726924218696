import { Carousel } from "@mantine/carousel";
import {
  Card,
  Image,
  Group,
  Text,
  Badge,

  CardSection,
} from "@mantine/core";
import { testimonials } from "../../../util/constants";
import styles from "../../../util/styles";
type Props = {};

const Carsoul = (props: Props) => {
  return (
    <>
    <h2 className={ `${styles.bblue6} ${styles.heading1} text-center xs:px-2`}> Hear it from our Partners</h2>
    <Carousel
      className="m-12 xs:m-1 mt-0"
      slideSize="25%"
      height={625}
      align="start"
      slideGap="lg"
      controlsOffset="xs"
      dragFree
      withControls={false}
    >
      {testimonials.map((testimonial, index) => (
        <Carousel.Slide p="sm" className="" key={testimonial.badge}>
          <Card
            shadow="sm"
            p="sm"
            radius="md"
            withBorder 
            className="bg-gray-gradient border-slate-900 m-2 md:m-12 "
          >
            <CardSection className="">
              <Group position="right" mt="md" mb="md" mr="md" ml="sm" className="flex flex-col justify-center text-center">
                <Image src={testimonial.img} height={200} alt={testimonial.alt} radius='md' width={200}   />
                <Badge
                  color="black"
                  variant="light"
                  className="bg-blue-gradient text-slate-900"
                >
                  {testimonials[index].badge}
                </Badge>
       
                <Text weight={500} className={`${styles.bblue3}`}> {testimonials[index].title} </Text>

                <Text size="sm" color="white" className={`${styles.bblue5}${styles.paragraph}`}>
                  {testimonials[index].description}
                </Text>
                </Group>
            </CardSection>
          </Card>
        </Carousel.Slide>
      ))}
    </Carousel>
    </>
  );
};

export default Carsoul;
