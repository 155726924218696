import { Carousel } from "@mantine/carousel";
import { Card, Text } from "@mantine/core";

import { servicesteps } from "../../../util/constants";
import styles from "../../../util/styles";

type Props = {};

const ServiceSteps = (props: Props) => {
  return (
    <>
         <div className="absolute z-[0] w-[50%] h-[50%] right-20 top-50  bg-bluemain-gradient opacity-50 " />
        <h2 className={`${styles.bblue3} w-full text-center ${styles.heading1} m-5 mt-60 ml-0` }>ServiceSteps </h2>
      <div className="flex mt-8">
    <div className=" justify-center items-center flex flex-col md:flex-row flex-wrap md:p-8">
    {/* bg-gray-gradient border-slate-900 */}
        <Carousel align='start' slideSize="100%" height={800} slideGap="sm" controlsOffset="xs" loop dragFree withControls={false} withIndicators >
          {servicesteps.map((servicestep) => (
            <div className=" flex-1 justify-center flex-row items-center mt-6  text-center w-[280px] md:w-[500px] m-4 p-3 " key = {servicestep.id}>
              <Card shadow="sm" p="lg" radius="md" withBorder className="login-boxx2">
                <Text weight={500} size="lg" mt="md" className={`${styles.heading2} ${styles.bblue3} text-[28px] mt-0  leading-10`}>
                  {servicestep.title}
                </Text>

                <Text size="sm" color="dimmed"className={`${styles.bblue1} text-[18px] mt-0 `}>
                  {servicestep.description}
                </Text>

                {/* <Button
                  variant="light"
                  color="blue"
                  fullWidth
                  mt="md"
                  radius="md"
                >
                  Book classic tour now
                </Button> */}
              </Card>
              
            </div>
          ))}
              </Carousel>
        </div>
      </div>
    </>
  );
};

export default ServiceSteps;
