import  { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import ContactForm from "../components/Contact/ContactForm/ContactForm";
import ContactHeader from "../components/Contact/ContactHeader/ContactHeader";
import ContactHero from "../components/Hero/ContactHero";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Navbar/Footer";
import Awards from "../components/Stats/Awards";
import Social from "../components/Stats/Social";
import Stats from "../components/Stats/Stats";
import styles from "../util/styles";
import { getAnalytics, logEvent } from "firebase/analytics";
import { analytics } from "../firebase";
type Props = {};

const Contact = (props: Props) => {
  
  useEffect(() => {
    logEvent(analytics, "page_view_contact");
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <Helmet>
        <title>Zardux Digital Contact </title>
        <meta name="description" content="Zardux Digtal Top agency in Grand Rapids,MI avalible anytime at support@zarduxdigital.com for any marketing services with reliability and distinguished consistency" />
        <meta name="keywords" content="Grand Rapids, Contact, Email" />
        <meta name="author" content="Zardux Digital" />

  <meta property="og:url" content="https://zarduxdigital.com/contact"/>
  <meta property="og:type" content="website"/>
  <meta property="og:title" content="Zardux Digtal Contact"/>
  <meta property="og:description" content="Zardux Digtal avalible anytime at support@zarduxdigital.com for any marketing services with reliability and distinguished consistency" />
  <meta property="og:image" content="#"/>


  <meta name="twitter:card" content="summary_large_image"/>
  <meta property="facebook:domain" content="@zarduxdigital"/>
  <meta property="facebook:url" content="https://zarduxdigital.com/"/>
  <meta name="facebook:title" content="Zardux Digtal"/>
  <meta name="facebook:description" content="Zardux Twitter"/>
  <meta name="facebook:image" content="#"/>
    <link rel="canonical" href="https://zarduxdigital.com/contact" />


</Helmet>
    <div className="bg-primary w-full  overflow-hidden">
      <div className={`${styles.paddingX} ${styles.flexCenter}`}>
        <div className={`${styles.boxWidth}`}>
          <Navbar />
        </div>
      </div>
      <div className=" flex justify-center h-full ">
        <ContactHeader />
      </div>
      <div className=" flex justify-center h-full ">
        <ContactForm />
      </div>
      <div className="w-full flex justify-center items-center text-center mt-28">
     <Social/>
      </div>
      <div className='my-16 md:py-2'>
      <ContactHero />
      </div>
      <div className='my-16 md:py-2 p-8 md:px-32'>
      <Stats />
</div>
<div className='my-16 md:py-2 p-8 md:px-28'>
      <Awards/>
      </div>
      <div className="p-10 px-12 xs:m-3">
<Footer/>
</div>
    </div>
    </>
  );
};

export default Contact;
