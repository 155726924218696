

import styles from '../../util/styles'
import Statss from './Statss'

type Props = {}

const Stats = (props: Props) => {
  return (
    <>
    <div className=''>
        <div>
            <h2 className={`text-white w-full text-center ${styles.heading1}`}> The Numbers Speak for Themself</h2>
        </div>

      <div className='mt-6'>

    <Statss/>
      </div>
      </div>
    </>
  )
}

export default Stats