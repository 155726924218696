import { socialMedia } from '../../util/constants'
import styles from '../../util/styles'

type Props = {}

const Social = (props: Props) => {
  return (
    <>
    <div className=' flex-col'>
        <div>
        <h2 className={`${styles.bblue4} w-full text-center ${styles.heading1}`}> Social</h2>
        </div>
        <div className=' bg-black-gradient-2 rounded flex md:flex-row flex-col text-center items-center justify-around'>
        {socialMedia.map((award) => (
      <div key={award.id} className ='m-4 p-1 flex text-center justify-between items-center'>
        <img src ={award.icon} alt={award.name} title={award.title}
         width={'100%'} height = {'inherit'}
        onClick={() => window.open(award.link)} />
      </div>
            ))}
            </div>
    </div>
    </>
  )
}

export default Social