import {  test2 } from "../../util/assets";
import styles, { layout } from "../../util/styles";


const CardDeal = () => (
  <section className={layout.section}>
    <div className={`${layout.sectionInfo} pl-12 `}>
      <h2 className={`${styles.heading2} ${styles.bblue1} text-gradient`}>
        Find a right apporch <br className="sm:block hidden" /> in few easy
        steps.
      </h2>
      <p className={`${styles.paragraph} ${styles.bblue1} max-w-[470px] mt-5`}>
      Consistency breeds confidence —  We pair the ideal strategy, based on your needs to make every touchpoint your audience experiences impactful and offerers purpose. 
      </p>

      {/* <Button styles={`mt-10`} /> */}
    </div>

    <div className={layout.sectionImg}>
      <img src={test2} alt="Find Digtal Services" title="Explore Digital Services" width={'100%'} height = {'inherit'} className="w-[100%] h-[100%]" />
    </div>
  </section>
);

export default CardDeal;