import React from 'react'
import styles from '../../../util/styles'

type Props = {}

const ContactHeader = (props: Props) => {
  return (
    <div className="flex flex-col justify-center text-center items-center mt-28 px-6">
 <h1 className={`${styles.bblue4} w-full text-center ${styles.heading1}`}> Contact Us For a Free Quote</h1>
 <span className={`${styles.bblue1}`}>-----</span>
 <h2 className={` ${styles.bblue2} md:text-[18px] bg-black-gradient-2 rounded-md p-2 px-6 m-4`}> We'll return your message or call within 24 hours, M-F </h2>
        
        </div>
  )
}

export default ContactHeader