import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from '../../util/styles'
import { useState } from 'react';
import { Modal, Button, Group } from '@mantine/core';
import { doc, setDoc } from 'firebase/firestore';
import db from '../../firebase';
type Props = {}

const ModalPopup = (props: Props) => {
    const [opened, setOpened] = useState(false);
    const [email, setEmail] = useState("");
    const [loader, setLoader] = useState(false);
    const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoader(true);
    const data = {

      email: email,

    };

    const docRef = doc(db, "FreeDoc", `${email}`);
    await setDoc(docRef, data);

    console.log(data);

    setEmail("");
  };


  return (
    <>
     <div className='flex justify-center flex-col w-full  mx-14 mt-0 pl-0 ml-0 '>
     <h2 className={`${styles.bblue3} w-full text-center ${styles.heading1} m-5 mt-5 ml-0` }>Claim a Free Marketing Checklist </h2>
      <Modal centered 
        withCloseButton={false}
        opened={opened}
        onClose={() => setOpened(false)}
        style={{background: 'transparent'}}
        overlayOpacity={0.5}
        // size="sm"
        radius="lg"
        padding = {0} 
        className={`flex flex-col justify-center items-center text-center `}>
        {/* Modal content */}

        <form
        className="flex flex-col rounded-lg justify-center z-20  bg-gray-gradient "
        onSubmit={handleSubmit}
      >

        <h1 className={`text-center ${styles.bblue2} ${styles.heading2} text-[2rem] p-2 px-4 `}>
          Get a Complete 2022 Marketing Checklist
        </h1>
        <div className="">
          
          <div className="flex flex-col justify-center items-center mt-3 ">
    
            <input
              className={`w-[70%] m-4 mt-0 justify-center 
              formtext 
             box-blur ${styles.bblue2} outline-none`}
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="flex flex-col justify-center items-center">
            <button
              className="w-[60%] m-4 mt-3 justify-center rounded-md formbutton text-black text-[2rem] mb-12"
              type="submit"
              style={{ background: loader ? "#ffffff" : `#5ce1e6` }}
              onClick={() => setOpened(false)}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
     
             {/* Modal content */}
      </Modal>

      <Group position="center">
        <Button onClick={() => setOpened(true)} className={` py-2 px-4 m-2 text-center ${styles.paragraph} bg-pink__gradient  text-white rounded-md z-30`}>Continute</Button>
      </Group>
      </div>
    </>
  );
}


export default ModalPopup