// Import the functions you need from the SDKs you need
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getDatabase, ref } from 'firebase/database';
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEbTq6z6sRtofPkWe-5_pxR8ySbLaPuo8",
  authDomain: "zardux-digital-website.firebaseapp.com",
  databaseURL: "https://zardux-digital-website-default-rtdb.firebaseio.com",
  projectId: "zardux-digital-website",
  storageBucket: "zardux-digital-website.appspot.com",
  messagingSenderId: "860969793216",
  appId: "1:860969793216:web:1f7f08f9826b836c2b1f25",
  measurementId: "G-1S6PX3E1B7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
export const analytics = getAnalytics(app);

export default db;

