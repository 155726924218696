
import ReactDOM from 'react-dom/client';
import './index.css';
import './app.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import {HelmetProvider} from 'react-helmet-async'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <HelmetProvider>
  <MantineProvider >

    <App />
  </MantineProvider>
    </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
