import React from 'react'
import { useNavigate } from 'react-router-dom';
import styles from '../../util/styles'

type Props = {}

const CallToAction = (props: Props) => {
  const navigate = useNavigate();
  const handlenav = () => {
    console.log("clicked");
    navigate("/contact");
    window.scrollTo(0, 0);
  }
  return (
    <div className='flex justify-center flex-col w-full h-4/5 m-14 mt-20 pl-0 ml-0 '>
        <div className='text-center'>
<h2 className= {`w-full text-center ${styles.heading1} ${styles.bblue1} text pl-0 `}> Take your Buisness to the next Level <br/> Lets Go!</h2>
        </div>
        <div className='flex text-center  w-full justify-center '>
            <button className={` py-2 px-4 m-2 text-center ${styles.paragraph} bg-pink__gradient  text-white rounded-md z-30`} onClick = {handlenav}> Get Started</button>
        </div>
    </div>
  )
}

export default CallToAction