import { test6 } from "../../util/assets";
import styles from "../../util/styles";
const ContactHero = () => {
  return (
    <section
      id="home"
      className={`flex md:flex-row flex-col ${styles.paddingY}`}
    >
      <div
        className={`flex-1 ${styles.flexStart} flex-col xl:px-0 sm:px-16 px-6 md:flex-row `}
      >
        <div className="flex flex-row items-center px-4 bg-discount-gradient rounded-[10px] md:mr-10">
          <div
            className={`flex-1 flex ${styles.flexCenter} md:my-0 my-10 relative`}
          >
            <img
              src={test6}
              alt="billing"
              width={100}
              height={800}
              className="w-[38rem] h-[28rem] relative z-[5] "
            />
          </div>
        </div>
        <div className="text-center">
          <div className="flex flex-row justify-between items-center w-full ">
            <h2 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] md:pt-14 xs:pl-6">
              {/* The Next <br className="sm:block hidden" />{" "} */}
              <span className="text-gradient text-center">Lets Grow</span>{" "}
            </h2>
          </div>
          <h2 className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] w-full">
            Together.
          </h2>

          {/* <p className={`${styles.paragraph} max-w-[470px] mt-5`}>
          Our team of experts uses a methodology to identify the credit cards
          most likely to fit your needs. We examine annual percentage rates,
          annual fees.
        </p> */}
        </div>
      </div>
    </section>
  );
};

export default ContactHero;
