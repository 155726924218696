import { useNavigate } from "react-router-dom";
import { test8 } from "../../util/assets";
import styles from "../../util/styles";

const ServicesHero = () => {

  const navigate = useNavigate();
  const handlenav = () => {
    console.log("clicked");
    navigate("/contact");
    window.scrollTo(0, 0);
  }

  return (
    <section className={`flex justify-center flex-col  xl:px-0 sm:px-16 px-6 mt-28` }>
      <div className={`flex ${styles.flexStart} flex-col-reverse md:flex-row  xl:px-0 sm:px-16 md:px-18 lg:mt-36 lg:mb-36 md:justify-around `}>
        <div className={`flex  ${styles.flexCenter} md:my-0 my-10 relative `}>
        <div className={` flex flex-col text-center  ${styles.flexCenter} md:items-center md:justify-around md:mt-35 md:mx-3.5 md:my-10 relative `}>
        <img src={test8} alt="billing" className="w-[100%] h-[100%] relative z-[5]" />
        <div className='flex text-center  w-full justify-center mt-6'>
            <button className={` py-2 px-4 m-2 text-center ${styles.paragraph} bg-pink__gradient  text-white rounded-md z-[6]`} onClick ={handlenav}> Get Started</button>
        </div>
</div>

        {/* gradient start */}
        <div className="absolute z-[0] w-[40%] h-[35%] top-0 pink__gradient" />
        <div className="absolute z-[1] w-[80%] h-[80%] rounded-full white__gradient bottom-40" />
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 bottom-20 blue__gradient" />
        {/* gradient end */}
      </div>
      <div className="flex flex-col m-5 ml-8 md:pr-12"> 
        <div className="flex flex-row justify-between items-center w-full">
          <h2 className="flex-1 font-poppins font-semibold ss:text-[72px] text-[52px] ss:leading-[100.8px] leading-[75px]">
            <span className={`text-gradient ${styles.bblue1}`}>Bring Clearity</span>{" "}
          </h2>

        </div>
<h1 className="hidden"> Best Markeing Services</h1>
        <h2 className="font-poppins font-semibold ss:text-[68px] text-[52px] text-white ss:leading-[100.8px] leading-[75px] w-full">
          To marketing.
        </h2>
        <p className={`${styles.paragraph} ${styles.bblue2} max-w-[470px] bg-gray-gradient rounded-md p-3 text-center mt-5 `}>
        Zardux Digital uses a methodology to identify the best strategy
          paired with years of expensive and artificial intelligence to analyze performance and analytics to
          ensure you get the best results.
        </p>
      </div>
      </div>

    </section>
  );
};

export default ServicesHero;
