import styles from "../../util/styles";
import { arrowUp } from "../../util/assets";

const GetStarted = () => {
  const handlenav = () => {
      window.scrollTo(0, 0);
      console.log("clicked");
    };
    return(
  <div className={`${styles.flexCenter} w-[140px] h-[140px] rounded-full bg-blue-gradient p-[2px] cursor-pointer`}onClick ={()=>handlenav}>
    <div className={`${styles.flexCenter} flex-col bg-primary w-[100%] h-[100%] rounded-full`}>
      <div className={`${styles.flexStart} flex-row`}>
        <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
          <span className="text-gradient">Get</span>
        </p>
        <img src={arrowUp} alt="arrow-up" 
         width={'100%'} height = {'inherit'}
        className="w-[23px] h-[23px] object-contain" />
      </div>
      
      <p className="font-poppins font-medium text-[18px] leading-[23.4px]">
        <span className="text-gradient">Started</span>
      </p>
    </div>
  </div>
);
};
export default GetStarted;
