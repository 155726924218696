import styles from "../../../util/styles";

type Props = {};

const ServicePre = (props: Props) => {
  return (
    <>
      <h2  className={`${styles.bblue5} w-full text-center ${styles.heading1} p-4 md:m-5 mt-8 ml-0` }> Problems Zardux Strategy Solves</h2>
      <div className=" bg-black-gradient-2 rounded-md flex justify-center item-center flex-col md:flex-row  text-center p-8 m-14  ">
        <div className="p-3  flex items-center justify-center flex-col">
          <h3 className={`${styles.bblue2} text-[24px] md:text-[32px] items-center text-center md:w-2/3 font-semibold` }>
            Are you noticing a lack of connectivity between your digital
            marketing efforts?
          </h3>
          <span className="text-white">-----</span>
          <h4 className={` ${styles.bblue1} ${styles.paragraph}  md:text-[18px] px-5 leading-9`}>
            You've spread yourself too thin by being active on every channel–by
            overextending yourself, you reduce the return on your efforts.
            Likewise, you may be missing out on opportunities to reach your
            audience by focusing on every method. <br/> In realiity, one strategy
            done right is better than a dozen done poorly.
          </h4>
        </div>
        <div className="p-3  flex items-center justify-center flex-col">
          <h3 className={`${styles.bblue2} text-[24px] md:text-[32px] items-center text-center md:w-2/3 font-semibold` }>
            Do you not know what to do and just need answers?
          </h3>
          <span className="text-white">-----</span>
          <h4 className={` ${styles.bblue1} ${styles.paragraph}  md:text-[18px] px-5 leading-9`}>
           We are a parnter in your business, not just an adviser who takes your money and dishes out a few titbits of advice but a partner who wants to see you succeed. 
          <br/>
           We are here to help you grow your business and we will do everything in our power to help you achieve your goals. Providing cutting edge strategies and tactics to help you grow your business is our passion. 
          </h4>
        </div>
      </div>
    </>
  );
};

export default ServicePre;
