import { Carousel } from "@mantine/carousel";
import { Card, Text } from "@mantine/core";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CallToAction from "../components/CallToAction/CallToAction";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Navbar/Footer";
import Social from "../components/Stats/Social";
import { services } from "../util/constants";
import styles from "../util/styles";

import { Helmet } from "react-helmet-async";

import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";


type Props = {};

function ServiceSingle({}: Props) {
  
  const param = useParams();
  const item = param;
  const serviceitem = services.find((item) => item.id === param.id);
  
  // useEffect(() => {
    //   console.log(item);
    //   console.log(serviceitem);
    // }, [item]);
    useEffect(() => {
    logEvent(analytics, `${serviceitem}`)

    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
     <Helmet>
        <title>{`${serviceitem?.seo.title}`}</title>
        <meta name="description" content={`${serviceitem?.seo.mdes}`} />
        <meta name="keywords" content="Zardux Digital, Digital Marketing, SEO, Social Media Marketing, Email Marketing" />
        <meta name="author" content="Zardux Digital" />
        <link rel="canonical" href={`https://zarduxdigital.com/services/${serviceitem?.seo.canonical}`} />

</Helmet>
      <div className="bg-primary w-full  overflow-hidden ">
        
        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth} flex`}>
            <Navbar />
          </div>
        </div>

        <div className="flex flex-col justify-center text-center items-center ">
          <h1 className={`${styles.bblue1} ${styles.heading1} text-center mt-28 box-blue p-3`}>
            {serviceitem?.title2}
          </h1>

          <div
            className={`md:w-2/5 w-4/5 rounded-md bg-gray-gradient ${styles.bblue1}   z-[1] `}>
            <h2 className={` pt-8 px-4 w-full text-center ${styles.paragraph}`}>
              {serviceitem?.description}
              <br />
              <br />
              {/* Voluptate cum unde hic suscipit, laboriosam laudantium. Tempore autem unde blanditiis quod vel voluptatibus eligendi, ea, ullam consequatur dolorem, totam nobis ipsa. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Numquam alias eos nulla illum? Velit aperiam  */}
              <br />
            </h2>
          </div>
          {/* gradient */}
          <div className="absolute  z-[0] w-[70%] xs:w-[100%] xs:h-[80%] h-[25%] -left-70 top-0  pink__gradient" />
          <div className="absolute -z-[1] md:w-[80%]  w-[70%] xs:w-[100%] xs:h-[80%] h-[80%] rounded-full white__gradient bottom-40" />
          <div className="absolute -z-[1] md:w-[50%] w-fill h-[50%] right-20 bottom-20 blue__gradient" />
        </div>
        {/* gradient */}

        <div className=" flex flex-col h-full  md:flex-row items-center text-center mt-28 xs:mt-8 px-10 justify-center mb-3">
          <div className=" flex justify-center  pr-8 pl-7 z-10 md:p-0  xs:mb-6 ">
            <img src={serviceitem?.img1} title ={`${serviceitem?.title}`} alt={`${serviceitem?.seo.imalt}`} className="md:pl-1"
           width={'100%'} height = {'inherit'}  />
            <div className=" xs:hidden w-2/3">
              <div className="absolute xs:w-[80%] xs:h-[100%]  md:w-[50%] md:h-[100%] md:opacity-10 bottom-4/5 left-0 white__gradient2  opacity-25" />
            </div>
          </div>
          <div className=" md:hidden w-2/3">
            <div className="absolute xs:w-[70%] xs:h-[100%]  md:w-[80%] md:h-[100%] md:opacity-10 bottom-4/5 white__gradient2  opacity-25" />
          </div>
          <div className="md:w-3/5 ">
            <h3
              className={`${styles.bblue6} w-full text-center ${styles.heading1}`}
            >
              {" "}
              {'Advatages'}
            </h3>
            <h3
              className={` w-full text-center ${styles.paragraph} text-white xs:p-5 xs:pt-0 md:mr-10 md:pr-7 `}
            >
            {serviceitem?.description2}
            </h3>
          </div>
        </div>

        <div className="w-full justify-start items-center text-center mt-12 mb-0 z-2 xs:p-4">
          <div className="absolute z-[0] w-[40%] h-[35%]  xs:w-[90%] xs:h-[80%] top-70 pink__gradient opacity-60" />
          <div className="absolute z-[0] w-[80%] h-[80%] xs:w-[90%] xs:h-[80%] rounded-full white__gradient bottom-240 opacity-30" />
          <div className="absolute z-[0] w-[50%] h-[50%]  xs:w-[90%] xs:h-[80%]  xs:opacity-95 right-60 bottom-120 blue__gradient opacity-30" />
          <div className="sx:mx-1">
          </div>
        </div>
        <div className="absolute z-[0] w-[50%] h-[50%] right-20 top-50  bg-bluemain-gradient opacity-50 " />
        <h2 className={`${styles.bblue3} w-full text-center ${styles.heading1} m-5 mt-12 md:mt-60 ml-0` }>Questions? </h2>
      <div className="flex mt-8">
    <div className=" justify-center items-center flex flex-col md:flex-row flex-wrap md:p-8">
    {/* bg-gray-gradient border-slate-900 */}
        <Carousel align='start' slideSize="100%" height={650} slideGap="sm" controlsOffset="xs" loop dragFree withControls={false} withIndicators >
          {serviceitem?.about?.map((item) => (
            <div className=" flex-1 justify-center flex-row items-center mt-6  md:ml-9 text-center w-[280px] md:w-full m-4 p-1 " key = {item.id}>
              <Card shadow="sm" p="lg" radius="md" withBorder className="login-boxx2">
                <Text weight={500} size="lg" mt="md" className={` ${styles.bblue3} text-[1.5rem] py-4 mt-0`}>
                  {item.title}
                </Text>
                  <Text size="sm" color="dimmed"className={`${styles.bblue1} text-[18px] mt-0 `}>
                  {item.about}
                </Text> 
              </Card>
              
            </div>
          ))}
              </Carousel>
        </div>
      </div>

        <div className=" flex h-full items-center text-center mt-12 justify-around sm:flex-col w-full">
          <div className=" flex justify-center sm:flex-col sm:flex xs:m-3 w-2/7">
            <Social />
          </div>
        </div>

        {/* <div className=" flex h-full items-center text-center mt-12 justify-around sm:flex-col w-full">
    <div className=" flex justify-center sm:flex-col sm:flex xs:m-3 w-3/4">
    <Awards/>
      </div>
      </div> */}

        {/* <div className="absolute z-[0] w-[80%] h-[20%] xs:w-[100%] xs:h-[50%] xs:m-3 xs:-bottom-4/5 rounded-full bg-bluemain-gradient md:white__gradient right-3 -bottom-500 opacity-50 md:opacity-30 overflow-hidden" /> */}

        <div className="mx-4 xs:m-3">
          <CallToAction />
        </div>

        <div className="p-8 xs:m-3">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default ServiceSingle;
