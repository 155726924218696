import { Link } from "react-router-dom";
import styles from "../util/styles";
import Calender from "./Services/Calendar";

export function NotFound() {


  return (
<>
<div className = "bg-primary w-full h-[100vh]  overflow-hidden "> 
<div className = { `${ styles.paddingX } ${ styles.flexCenter } items-center w-full h-full` } >
<div className="white__gradient w-3/4 h-3/4 xs:w-2/3 md:top-5 md:left-5  top-20 right-64 absolute"></div>
      <div className="box-shadow w-3/4 h-3/4 xs:w-2/3 md:top-5 md:left-5  absolute"></div>
  <div className=" z-10 login-boxx flex justify-center items-center w-2/5 h-2/5 p-12">  
<Link to="/">
<h1 className={`${styles.bblue6} text-bold text-[4rem] `}> Not <br/> Found</h1>
  </Link>
  </div>
<Calender/>
</div>
</div>
</>
  );
}