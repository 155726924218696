
import { features } from "../../util/constants";
import styles, { layout } from "../../util/styles";


const FeatureCard = ({ icon, title, content, index }:any) => (
  <div className={`flex flex-row p-6 rounded-[20px] ${index !== features.length - 1 ? "mb-6" : "mb-0"} feature-card`}>
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter} bg-dimBlue`}>
      <img src={icon} alt="star"  width={'100%'} height = {'inherit'} className="w-[50%] h-[50%] object-contain" />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4>
      <p className="font-poppins font-normal text-dimWhite text-[16px] leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const Business = () =>  (
      <>
      <div className="flex md:flex-col-reverse">
    <section id="features" className={`${layout.section} md:flex-row-reverse `}>
    <div className={`${layout.sectionInfo} p-8 xs:p-0 felx `}>
        
      <h3 className={`${styles.heading2} ${styles.bblue2} `}>
      Your business deserves growth, <br className="sm:block hidden" /> We'll build a community
      </h3>
      <p className={`${styles.paragraph}  text-white  max-w-[470px] mt-5 `}>We fuel the growth of purpose driven business through strategy activation, designed success, and market adoption to handle any situation. 
      </p>

      {/* <Button styles={`mt-10`} /> */}
    </div>

    <div className={`${layout.sectionImg} flex-col md:pr-16`}>
      {features.map((feature, index) => (
        <FeatureCard key={feature.id} {...feature} index={index} />
        ))}

    </div>
  </section>
    </div>
  </>
);

export default Business;