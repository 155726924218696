import { Button, Card, Text, Image } from "@mantine/core";
import  { useEffect } from "react";
import {useNavigate, useParams } from "react-router-dom";
import { services } from "../../../util/constants";
import styles from "../../../util/styles";

type Props = {};

const ServiceList = (props: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // const param  =useParams()
  // const item = param.id


const navigate = useNavigate();
 
// const handleClick = () => {
//   navigate(`/services/${services.id}`);
// };
// useEffect(() => {
//     console.log(item)
//     console.log(param)
// }, [item])

  return (
    <>
        <h2 className={`${styles.bblue2} w-full text-center ${styles.heading1} md:m-5 mt-8 ml-0` }> Explore Our Services</h2>
      <div className="justify-center items-center flex flex-col md:flex-row  flex-wrap">

        {services.map((service) => ( 
        <div className=" flex-1 justify-center flex-row  text-center w-3/4 m-4 p-3" key={service.id}>
          <div className=" md:hidden absolute z-[2] w-[80%] h-[80%] rounded-full white__gradient2 opacity-25 md:opacity-5 xs:w-[100%] xs:h-[100%] xs:z-[1]" />
          <Card shadow="sm" p="lg" radius="md" withBorder className="bg-gray-gradient border-slate-900 z-20" >
            <div className="flex justify-around flex-wrap md:flex-nowrap">
              <Image
                src={service.icon}
                height={190}
                width={160}
                alt={service.title}
                title={service.title}
                className=""
              />
              <Text weight={500} size="xl" mt="md" className={` ${styles.bblue3} text-[1.5rem]   md:m-4 md:pt-8 md:pl-8`}>
                {service.title}
              </Text>
            </div>

            <Text size="sm" color="dimmed" className={`${styles.bblue1} text-[1rem]  p-1.5`}>
           {service.meta}
            </Text>
             {/* <Link to = {`${service.id}`}>  */}
            <Button variant="light" color="blue"  fullWidth mt="md" radius="md" className='bg-blue-gradient2 feature-card2' onClick ={() => navigate(`/services/${service.id}`) } >
             Learn More 
            </Button>
              {/* </Link> */}
          </Card>
        </div>
        ))}
      </div>
    </>
  );
};

export default ServiceList;
