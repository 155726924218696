
import Navbar from '../components/Navbar/Navbar';
import styles from '../util/styles';

import Hero from '../components/Hero/Hero';
import  Footer  from '../components/Navbar/Footer';
import Clients from '../components/Stats/Client';
import Statss from '../components/Stats/Statss';

import Business from '../components/Home/Buisness';
import CardDeal from '../components/Home/CardDeal';
import Carsoul from '../components/UI/Carsoul/Carsoul';

import Awards from '../components/Stats/Awards';
import { useEffect } from 'react';
import ModalPopup from '../components/CallToAction/modalpopup';
import Calender from '../components/Services/Calendar';

import { Helmet } from 'react-helmet-async';


import { getAnalytics, logEvent,setUserId} from "firebase/analytics";
import { analytics } from '../firebase';


import ReactPixel from 'react-facebook-pixel';
function Home() {



  const advancedMatching = { ct:'',
  em: '',
  fn: '',
  ln: '',
  ph: '',
  st: '',
  zp: '' ,
  db: '',
  ge: '',
  country: '',};
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init('531194721927399', advancedMatching, options);
  
  const date = new Date().toLocaleDateString();
  useEffect(() => {
    ReactPixel.pageView(); // For tracking page view
    window.scrollTo(0, 0);
    logEvent(analytics,'page_view_home');
    setUserId(analytics, `${date}`);
    }, []);

  return (
    <>
<Helmet>
        <title>Zardux Digital</title>
        <meta name="description" content="Zardux Digtal provides advanced digital marketing services incuding SEO, Social Meida Marketing and Email Makerketing" />
        <meta name="keywords" content="Zardux Digital, Digital Marketing, SEO, Social Media Marketing, Email Marketing" />
        <meta name="author" content="Zardux Digital" />

  <meta property="og:url" content="https://zarduxdigital.com/"/>
  <meta property="og:type" content="Website"/>
  <meta property="og:title" content="Zardux Digtal"/>
  <meta property="og:description" content="Zardux Digtal provides advanced digital marketing services incuding SEO, Social Meida Marketing and Email Makerketing with advanced Ads"/>
  <meta property="og:image" content="#"/>


  <meta name="twitter:card" content="summary_large_image"/>
  <meta property="twitter:domain" content="@zarduxdigital"/>
  <meta property="twitter:url" content="https://twitter.com/zarduxdigital"/>
  <meta name="twitter:title" content="Zardux Digtal"/>
  <meta name="twitter:description" content=""/>
  <meta name="twitter:image" content="#"/>
  <link rel="canonical" href="https://zarduxdigital.com/" />


</Helmet>

   <div className="bg-primary w-full overflow-hidden">
    <div className={`${styles.paddingX} ${styles.flexCenter} sm:px-16 px-6 pb-8 sm:pb-14`}>
      <div className={`${styles.boxWidth}`}>
        <Navbar />
      </div>
    </div>

    <div className={`bg-primary ${styles.flexStart} pt-6`}>
      <div className={`${styles.boxWidth}`}>
        <Hero />
      </div>
    </div>
    
    <div className={`bg-primary ${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <div className='px-4 md:px-12'>
      <Statss />
      </div>
      <Business />
      <CardDeal />
      <Carsoul/>
      <div className='my-16 md:py-2'>
      <Awards/>
      </div>
      <div className='my-16 md:py-2'>
      <Clients />
      </div>
      <div className='my-16 md:py-2'>
      <ModalPopup/>
      </div>
      <Calender/>
<Footer/>
      </div>
    </div>
  </div>
    </>
  );
}

export default Home;
