
import { logEvent } from 'firebase/analytics'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import CallToAction from '../components/CallToAction/CallToAction'
import ServicesHero from '../components/Hero/ServicesHero'
import Navbar from '../components/Navbar/Navbar'
import Calender from '../components/Services/Calendar'
import Footer from '../components/Navbar/Footer'
import ServiceList from '../components/Services/ServiceList/ServiceList'
import ServicePre from '../components/Services/ServicePre/ServicePre'
import ServiceSteps from '../components/Services/ServiceSteps/ServiceSteps'
import { analytics } from '../firebase'
import styles from '../util/styles'

type Props = {}

const Services = (props: Props) => {

  
  useEffect(() => {
    logEvent(analytics,'page_view_services')
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
    <Helmet>
        <title>Digital Marketing Services</title>
        <meta name="description" content=" Digital Marketing Services to dominate your competition with these proven marketing strategies to make your business grow and produce more clients specifically with Ads." />
        <meta name="keywords" content="Zardux Digital, Digital Marketing, SEO, Social Media Marketing, Email Marketing" />
        <meta name="author" content="Zardux Digital" />
        <link rel="canonical" href="https://zarduxdigital.com/services" />
        <meta name="author" content="Zardux Digital" />
        <meta property="og:url" content="https://zarduxdigital.com/services"/>
  <meta property="og:type" content="Website"/>
  <meta property="og:title" content="Zardux Digtal"/>
  <meta property="og:description" content="Digital Marketing Services to dominate your competition with these proven marketing strategies to make your business grow and produce more clients specifically with Ads."/>
  <meta property="og:image" content="#"/>


  <meta name="twitter:card" content="summary_large_image"/>
  <meta property="twitter:domain" content="@zarduxdigital"/>
  <meta property="twitter:url" content="https://twitter.com/zarduxdigital"/>
  <meta name="twitter:title" content="Zardux Digtal"/>
  <meta name="twitter:description" content=""/>
  <meta name="twitter:image" content="#"/>

</Helmet>
       <div className="bg-primary w-full overflow-hidden">
    <div className={`${styles.paddingX} ${styles.flexCenter}`}>
      <div className={`${styles.boxWidth}`}>
        <Navbar />
      </div>
    </div>

    <div className={`bg-primary ${styles.flexStart}`}>
      <div className={`${styles.boxWidth}`}>
      <ServicesHero/>
      <ServiceList/>
 
      <ServiceSteps/>
      <div className="absolute z-[3] w-[40%] h-[35%] top-260 pink__gradient opacity-70 xs:w-[100%] xs:h-[100%] " />
      <div className="absolute z-[2] w-[80%] h-[80%] rounded-full white__gradient  right-0 top-10 opacity-50 xs:opacity-90 xs:w-[100%] xs:h-[100%] xs:z-10" />
      <div className=" md:hidden absolute z-[2] w-[80%] h-[80%] rounded-full white__gradient  right-0 top-10 opacity-50 xs:opacity-60 xs:w-[100%] xs:h-[100%] xs:z-10" />
      <ServicePre/>
      <CallToAction/>
      </div>
    </div>
      <div className="p-10 px-12 xs:m-3">
<Footer/>
</div>
      <Calender/>
    </div>
    </>
  )
}

export default Services