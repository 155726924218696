import { Link } from "react-router-dom";
import { logo } from "../../util/assets";
import { footerLinks, socialMedia } from "../../util/constants";
import styles from "../../util/styles";


const Footer = () => (
  <section className={`${styles.flexCenter} ${styles.paddingY} flex-col`}>
    <div className={`${styles.flexStart} md:flex-row flex-col mb-8 w-full`}>
      <div className="flex-[1] flex flex-col justify-start mr-10 ">
        <Link to={"/"}>
        <img
          src={logo}
          alt="Zardux Digital Logo"
          title="Zardux Digital"
          width={'100%'} height = {'inherit'}
          className="w-[16rem] h-[6rem] object-contain xs:m-0"
        />
        </Link>
        <p className={`${styles.paragraph} ${styles.bblue1} mt-4 max-w-[312px]`}>
          A new Age of Marking made easy, Lucrative and Exciting.
        </p>
        <a className={`font-poppins font-normal  ${styles.paragraph} ${styles.bblue6}`} title = "aa" href="https://calendly.com/zarduxdigital/free-marketing-assessment" target="_blank" rel="noreferrer"> Schedule An Appoitment </a>
      </div>

      <div className="flex-[1.5] w-full flex flex-row justify-between flex-wrap md:mt-0 mt-10">
        {footerLinks.map((footerlink) => (
          <div key={footerlink.title} className={`flex flex-col ss:my-0 my-4 min-w-[150px]`}>
            <h3 className={`font-poppins font-medium ${styles.paragraph} leading-[27px] text-white`}>
              {footerlink.title}
            </h3>
            <ul className="list-none mt-4">
              {footerlink.links.map((link, index) => (
                <li
                  key={link.name}
                  className={`font-poppins font-normal text-[16px] leading-[24px] text-dimWhite hover:text-secondary cursor-pointer ${
                    index !== footerlink.links.length - 1 ? "mb-2" : "mb-0"
                  }`}
                >
                  <Link to={`/${link.link}`}>
                  {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>

    <div className="w-full flex justify-between items-center md:flex-row flex-col pt-6 border-t-[1px] border-t-[#3F3E45]">
      <p className={`font-poppins font-normal text-center ${styles.paragraph}  text-white`}>
        Copyright Ⓒ 2022 Zardux Digital. All Rights Reserved.
      </p>
      <div className="flex flex-row md:mt-0 mt-6">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.name}
            title={social.title}
            width={'100%'} height = {'inherit'} 
            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </div>
    </div>
  </section>
);

export default Footer;