import { useEffect, useState } from "react";
import { PopupWidget } from "react-calendly";

const Calender = () => {
    const modalRoot = document.getElementById('modal-root') as HTMLElement;
    const input = document.getElementById('submit');
 const [show, setShow] = useState(true);
//  useEffect(() => {
//     setTimeout(() => {
//         setShow(false);
//     }, 10000);
//     }, []);
  return (
    <>
    {show && ( 
        
        <div className="bg-black xs:p-8">
<PopupWidget
              color="#33bbcf"
              text="Request a Call"
              textColor="#ffffff"
              url="https://calendly.com/zarduxdigital/free-marketing-assessment"     
              rootElement={modalRoot}
              />
    </div>
    )}
    
    </>
  
  );
};

export default Calender;